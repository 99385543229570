"use client";
import Script from "next/script";
import { analyticsGrouping, handleClick } from "../../lib/gtag";
import { useUserData } from "../hooks/useUserData";
import { useEffect, useRef } from "react";
import { usePathname } from "@/navigation";
import posthog from "posthog-js";

const FB_ID = process.env.NEXT_PUBLIC_FB_ID;

export function Analytics() {
  const { data: user, isLoading, isError } = useUserData();
  const pathname = usePathname();
  const savedPathNameRef = useRef(pathname);
  const previousUserRef = useRef(null);

  useEffect(() => {
    if (isLoading || isError || !user) return;

    // Trigger pageview if the route changes
    if (savedPathNameRef.current !== pathname) {
      const contentGroup = analyticsGrouping(pathname);
      const previousUser = previousUserRef.current;

      if (
        !previousUser ||
        previousUser.id !== user?.id ||
        previousUser.isLoggedIn !== user?.isLoggedIn ||
        previousUser.isSubscribed !== user?.isSubscribed ||
        previousUser.profile?.childDob !== user?.profile?.childDob ||
        previousUser.profile?.neighborhood !== user?.profile?.neighborhood
      ) {
        const userProps = {};

        if (user?.isLoggedIn !== undefined) {
          // @ts-ignore
          userProps.isLoggedIn = user.isLoggedIn;
        }

        if (user?.isSubscribed !== undefined) {
          // @ts-ignore
          userProps.isSubscribed = user.isSubscribed;
        }

        if (user?.profile?.neighborhood) {
          // @ts-ignore
          userProps.neighborhood = user.profile.neighborhood;
        }

        if (user?.tier) {
          // @ts-ignore
          userProps.tier = user.tier;
        }

        if (Object.keys(userProps).length > 0) {
          // @ts-ignore
          window.gtag("set", userProps);
          posthog.identify(user.id, userProps);
        }
        previousUserRef.current = user;
      }
      (window as any).gtag("event", "page_view", {
        page_path: pathname,
        content_group: contentGroup,
      });
      savedPathNameRef.current = pathname;
    }
  }, [pathname, isLoading, isError, user]);

  useEffect(() => {
    if (isLoading || isError || user.isAdmin) return;
    const rootElement = document.querySelector("body");
    rootElement?.addEventListener("click", handleClick);

    return () => {
      rootElement?.removeEventListener("click", handleClick);
    };
  }, [isLoading, isError, user]);

  if (isLoading || isError || !user) return null;

  const GA_ID =
    user.isAdmin && process.env.NODE_ENV === "production"
      ? ""
      : process.env.NEXT_PUBLIC_GA_ID;

  const config = {
    page_path: window.location.pathname,
    content_group: analyticsGrouping(window.location.pathname),
  };

  if (user?.id) {
    // @ts-ignore
    config.user_id = user.id;
  }

  if (isLoading) return;

  /**
   * Render analytics script tags
   */
  return (
    <>
      {/* Google Analytics Script */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
      />
      <Script
        id="google-tag--code"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_ID}', ${JSON.stringify(config)});

            function analyticsGrouping(path) {
              if (path.includes("/kitas/") || path.endsWith("/kitas")) return "kitas";
              if (path.includes("/events/") || path.endsWith("/events")) return "events";
              if (path.includes("/blog/") || path.endsWith("/blog")) return "blog";
              if (path.includes("/timeline/") || path.endsWith("/timeline")) return "timeline";
              if (path.includes("/perks/") || path.endsWith("/perks")) return "perks";
              if (path.includes("/guides/") || path.endsWith("/guides")) return "guides";
              return "other";
            }
          `,
        }}
      />
      {FB_ID && (
        <Script id="facebook" strategy="afterInteractive">
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${FB_ID}');
          fbq('track', 'PageView');
        `}
        </Script>
      )}
    </>
  );
}
